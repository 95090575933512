<template>
  <form action="#" @submit.prevent="submit">
    <div>
      <label for="email">Email address</label>
      <input type="text" name="email" id="email" v-model="form.email">
    </div>
    <div>
      <label for="password">Password</label>
      <input type="text" name="password" id="password" v-model="form.password">
    </div>
    <div>
      <button type="submit">
        Sign in
      </button>
    </div>
  </form>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    name: 'SignIn',

    data () {
      return {
        form: {
          email: 'robert@everday.se',
          password: '',
          json:true
        }
      }
    },

    methods: {
      ...mapActions({
        signIn: 'auth/signIn'
      }),

      submit () {
        // await this.signIn(this.form)

        // this.$router.replace({ name: 'home' })

        let self = this;
        console.log('Getting CSRF-cookie');
        axios.get('http://app.slack.o3industries.se/sanctum/csrf-cookie')
        .then(function(response){
          console.log('Logging in');
          console.log(self.form);
          axios.post('http://app.slack.o3industries.se/login', self.form)
          .then(function(subresponse){
            console.log('subresponse');
            console.log(subresponse);
          });
        });
        console.log('Done.');
      },
      test(){
        axios.get('http://app.slack.o3industries.se/user');
      }
    },
    mounted(){
      this.submit();
      let self = this;

      setTimeout(function(){
        self.test();
      }, 2000);

    }
  }
</script>