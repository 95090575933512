import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {
    // signIn ({ dispatch }, credentials) {
    //   console.log('Getting CSRF-cookie');
    //   axios.get('/sanctum/csrf-cookie')
    //   console.log('Logging in');
    //   console.log(credentials);
    //   axios.post('http://10.0.1.111:8000/login', credentials)
    //   console.log('Done.');

    //   return dispatch('me')
    // },

    // async signOut ({ dispatch }) {
    //   await axios.post('/logout')

    //   return dispatch('me')
    // },

    // me ({ commit }) {
    //   return axios.get('/api/user').then((response) => {
    //     commit('SET_AUTHENTICATED', true)
    //     commit('SET_USER', response.data)
    //   }).catch(() => {
    //     commit('SET_AUTHENTICATED', false)
    //     commit('SET_USER', null)
    //   })
    // }
  }
}